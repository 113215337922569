import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/page.js";
import Link from 'components/Link';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hi, I'm Spencer `}<span role="img" aria-label="wave">{`👋`}</span></h1>
    <p>{`My name is Spencer Schneidenbach! I'm a Microsoft MVP who likes to teach about REST APIs and ASP.NET Core. Feel free to `}<Link to="/contact" mdxType="Link">{`reach out`}</Link>{` to connect or ask questions :)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      